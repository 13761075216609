<template>
  <div class="serviceTrade">
    <div class="banner">
      <!-- <img class="bannerPos" src="@/assets/120200717125930241303.png" alt="" /> -->
      <div class="text">
        <div>
          <h2>为客户提供全球跨境投资及争议解决</h2>
          <h2>一站式法律服务</h2>
        </div>
        <p><img src="@/assets/bannertel.png" alt="" /> 400-662-2729</p>
      </div>
    </div>
    <div class="contain" id="htmlContent">
      <div class="title"><i></i>服务行业<span>Our services</span></div>
      <div class="box">
        <div class="boxTop">
          <ul>
            <li
              v-for="(x, i) in serviceList"
              :key="i"
              @click="show = x.id"
              :class="{ act: show == x.id }"
            >
              <img :src="x.logo" alt="" />
              <span>{{ x.name }}</span>
            </li>
          </ul>
        </div>
        <div class="boxBot">
          <div
            v-for="(x, i) in serviceList"
            :key="i"
            class="props"
            v-show="show == x.id"
          >
            <h4>{{ x.name }}</h4>
            <p>
              {{ x.content }}
            </p>
            <h4 class="h4Margin">服务详情</h4>
            <div class="propsBox">
              <div class="items" v-for="(item, j) in x.detailList" :key="j">
                <div class="tit">{{ item.name }}</div>
                <div class="bot">
                  <!-- <template v-if="item.detailContent != null"> -->
                  <span v-for="(detail, l) in item.detailContent" :key="l">{{
                    detail.name
                  }}</span>
                  <!-- </template>-->
                  <!-- <template v-else> -->
                  <!-- 暂无详情 -->
                  <!-- </template>  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: 0,
      serviceList: [], //服务行业
    };
  },
  watch: {
    $route() {
      //监听相同路由下参数变化
      let index = this.$route.hash.replace("#", "");
      index = index == 0 ? this.serviceList[0].id : index;
      this.show = index;

      setTimeout(function () {
        let htmlContent = document.getElementById("htmlContent");
        htmlContent.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    },
  },
  created() {
    this.getServiceList();
    this.$store.state.navChange = 2;
  },
  mounted() {
    setTimeout(function () {
      let htmlContent = document.getElementById("htmlContent");
      htmlContent.scrollIntoView({ behavior: "smooth" });
    }, 100);
  },
  methods: {
    //获得服务行业
    getServiceList() {
      this.axios
        .get(this.Url.apiUrl + "ServiceScope/GetServiceScope")
        .then((res) => {
          this.serviceList = res.data;
          let index = this.$route.hash.replace("#", "");
          index = index == 0 ? this.serviceList[0].id : index;
          this.show = index || this.$route.params.id || this.serviceList[0].id;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/serviceTrade.scss";
</style>